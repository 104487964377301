'use client'

import { useState, useEffect } from 'react'
import { ArrowRight, ArrowDown, CheckCircle, Database, Bell, CheckSquare, Clock, Check, Store, Search, Plus, Minus, Gauge, BellRing, Timer, ListChecks, Building2, Sparkles } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import axios from 'axios'
import { motion } from 'framer-motion'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

export default function ModernLandingPage() {
  const [profit, setProfit] = useState(0)
  const [time, setTime] = useState(60)
  const [showTime, setShowTime] = useState(false)
  const [screen, setScreen] = useState('profit')
  const [expandedIndex, setExpandedIndex] = useState(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const navigate = useNavigate()

  const handleCheckout = async () => {
    try {
      const apiEndpoint = `${window.location.origin}/api/create-checkout-session`
      const response = await axios.post(apiEndpoint, {}, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      })
      
      const stripe = await stripePromise
      if (!response.data.sessionId) {
        throw new Error('No session ID received')
      }
      
      const { error } = await stripe.redirectToCheckout({
        sessionId: response.data.sessionId
      })
      
      if (error) {
        console.error('Stripe error:', error)
        throw error
      }
    } catch (error) {
      console.error('Checkout error:', error)
      alert('Failed to start checkout. Please try again.')
    }
  }

  useEffect(() => {
    const profitInterval = setInterval(() => {
      setProfit(prev => {
        if (prev < 1000000) {
          return prev + 5000
        } else {
          clearInterval(profitInterval)
          setTimeout(() => {
            setScreen('time')
            setShowTime(true)
          }, 2000)
          return 1000000
        }
      })
    }, 30)

    return () => clearInterval(profitInterval)
  }, [])

  useEffect(() => {
    if (showTime) {
      const timeInterval = setInterval(() => {
        setTime(prev => {
          if (prev > 5) {
            return prev - 1
          } else {
            clearInterval(timeInterval)
            return 5
          }
        })
      }, 50)

      return () => clearInterval(timeInterval)
    }
  }, [showTime])

  const thoughts = [
    "I've been sourcing all day and not found a single profitable lead.",
    "My spend is so low this month, where am I going to find good items to buy?",
    "I've received so many notifications, I don't know where to start.",
  ]

  const features = [
    {
      icon: Gauge,
      title: "Amazon 2 Amazon (A2A) Database",
      description: "Browse thousands of profitable leads in our searchable database. Filter by brand, price, and profit to find profitable items within minutes."
    },
    {
      icon: BellRing,
      title: "A2A Notifications",
      description: "Create custom private filters to receive only the notifications that matter to you."
    },
    {
      icon: Timer,
      title: "A2A ASIN Price/Stock Monitor",
      description: "Track the price and stock of any item in UK & EU and get instant notifications, ensuring you never miss a price drop."
    },
    {
      icon: ListChecks,
      title: "Smart Notification Manager",
      description: "Keep your notifications organized by marking items as reviewed, helping you focus on fresh opportunities."
    },
    {
      icon: Building2,
      title: "Storefront Hunter",
      description: "Monitor 15 storefronts with instant alerts and automatic international A2A checking. Turn competitor listings into your next profitable opportunity."
    },
    {
      icon: Sparkles,
      title: "Automated Google Check (Coming Soon)",
      description: "Instant product research at scale - our system automatically checks Google for each item found on Storefront Hunter, eliminating hours of manual sourcing."
    }
  ]

  const stats = [
    { value: "300,000+", label: "Items Monitored" },
    { value: "40+", label: "Unique Price Monitors" },
    { value: "6+", label: "Countries" },
    { value: "100+", label: "Leads Found Per Day" }
  ]

  const faqs = [
    {
      question: "What marketplaces do you support?",
      answer: "We currently support Amazon marketplaces in the UK, US, Germany, France, Italy, and Spain."
    },
    {
      question: "How many ASINs can I monitor?",
      answer: "There is a current limit of 10 but will increase in the future."
    },
    {
      question: "What is an A2A lead?",
      answer: "An A2A (Amazon to Amazon) lead is a product opportunity where you can profitably source an item from Amazon and sell directly back on Amazon."
    },
    {
      question: "How often are prices updated?",
      answer: "The A2A database, A2A Notifications and ASIN Monitor are instant. Storefront Hunter is updated every 4 hours."
    },
    {
      question: "Will using Profit Hunter affect my Amazon account?",
      answer: "No, Profit Hunter is a third-party tool that doesn't interact directly with your Amazon account."
    },
    {
      question: "Can I cancel anytime?",
      answer: "Yes, you can cancel your subscription at any time. Your access will continue until the end of your current billing period."
    },
    {
      question: "Do you offer a refund?",
      answer: "We offer a 7-day money-back guarantee for new members. If you're not satisfied with our service, you can request a full refund within the first 7 days of your subscription."
    },
    {
      question: "I have another question",
      answer: "If you have any other questions, please don't hesitate to contact our support team. We're here to help!"
    }
  ]

  const toggleQuestion = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index)
  }

  return (
    <div className="min-h-screen w-full bg-gradient-to-b from-black to-purple-950 text-white overflow-x-hidden">
      <header className="sticky top-0 z-50 backdrop-blur-lg bg-black/50 border-b border-gray-800">
        <div className="container mx-auto px-4 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <motion.img
                initial={{ rotate: 0 }}
                animate={{ rotate: 360 }}
                transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Screenshot%202024-10-25%20at%2014.27.52-9vMmrwtQ51CudUygMtHtrFcKVPiB9d.png"
                alt="Profit Hunter Logo"
                className="h-10 w-10"
              />
              <span className="text-xl font-bold bg-gradient-to-r from-purple-400 to-pink-500 bg-clip-text text-transparent">
                Profit Hunter
              </span>
            </div>
            
            <nav className="hidden md:block">
              <ul className="flex space-x-8">
                <li>
                  <a href="#pricing" className="text-gray-300 hover:text-purple-400 transition-colors">
                    Pricing
                  </a>
                </li>
                <li>
                  <a href="#features" className="text-gray-300 hover:text-purple-400 transition-colors">
                    Features
                  </a>
                </li>
                <li>
                  <a href="#faq" className="text-gray-300 hover:text-purple-400 transition-colors">
                    FAQ
                  </a>
                </li>
              </ul>
            </nav>

            <button 
              onClick={() => navigate('/login')}
              className="hidden md:block px-6 py-2 bg-gradient-to-r from-purple-600 to-pink-600 rounded-full font-medium hover:opacity-90 transition-opacity"
            >
              Login
            </button>

            <button
              className="md:hidden text-gray-300"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"} />
              </svg>
            </button>
          </div>

          {/* Mobile menu */}
          {isMenuOpen && (
            <motion.div 
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="md:hidden pt-4"
            >
              <nav className="flex flex-col space-y-4">
                <a href="#pricing" className="text-gray-300 hover:text-purple-400 transition-colors">
                  Pricing
                </a>
                <a href="#features" className="text-gray-300 hover:text-purple-400 transition-colors">
                  Features
                </a>
                <a href="#faq" className="text-gray-300 hover:text-purple-400 transition-colors">
                  FAQ
                </a>
                <button 
                  onClick={() => navigate('/login')}
                  className="w-full px-6 py-2 bg-gradient-to-r from-purple-600 to-pink-600 rounded-full font-medium hover:opacity-90 transition-opacity text-center"
                >
                  Login
                </button>
              </nav>
            </motion.div>
          )}
        </div>
      </header>

      <main className="flex-1 w-full">
        <section className="container mx-auto px-4 py-24">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="grid gap-12 md:grid-cols-2"
          >
            <div className="flex flex-col justify-center">
              <h1 className="mb-6 text-5xl md:text-6xl font-bold leading-tight">
                Find <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-500">profitable</span> Amazon FBA products in <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-500">minutes</span>
              </h1>
              <p className="mb-8 text-xl text-gray-300">
                A powerful web app designed to simplify sourcing, helping you find profitable items quickly and grow your side hustle with ease.
              </p>
              <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => document.getElementById('pricing').scrollIntoView({ behavior: 'smooth' })}
                className="self-start px-8 py-3 bg-gradient-to-r from-purple-600 to-pink-600 rounded-full font-medium hover:opacity-90 transition-opacity inline-flex items-center"
              >
                Get Started
                <ArrowRight className="ml-2 h-5 w-5" />
              </motion.button>
            </div>

            <motion.div 
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="flex items-center justify-center rounded-2xl bg-gradient-to-br from-purple-900/50 to-pink-900/50 backdrop-blur-xl p-8 border border-purple-500/20"
            >
              <div className="text-center">
                {screen === 'profit' && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <h2 className="mb-4 text-2xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-500">
                      Watch profits grow
                    </h2>
                    <div className="text-6xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-500">
                      £{profit.toLocaleString()}+
                    </div>
                    <p className="mt-4 text-gray-300">Potential profits await...</p>
                  </motion.div>
                )}
                {screen === 'time' && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <h2 className="mb-4 text-2xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-500">
                      Time Spent Sourcing
                    </h2>
                    <div className="text-6xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-500">
                      {time} hours
                    </div>
                  </motion.div>
                )}
              </div>
            </motion.div>
          </motion.div>
        </section>

        <section className="py-24 bg-black/50">
          <div className="container mx-auto px-4">
            <div className="max-w-4xl mx-auto">
              <h2 className="text-3xl font-bold text-center mb-12 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-500">
                Transform Your Amazon FBA Business
              </h2>
              
              <div className="space-y-4 mb-12">
                {thoughts.map((thought, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    className="bg-purple-900/20 backdrop-blur-xl border border-purple-500/20 rounded-lg p-6"
                    style={{ marginLeft: `${index * 5}%`, maxWidth: '90%' }}
                  >
                    <p className="text-gray-300">{thought}</p>
                  </motion.div>
                ))}
              </div>
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                className="flex justify-center my-12"
              >
                <ArrowDown className="text-purple-400 h-12 w-12" />
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                className="bg-gradient-to-br from-green-900/50 to-emerald-900/50 backdrop-blur-xl border border-green-500/20 rounded-lg p-8"
              >
                <div className="flex items-start gap-4">
                  <CheckCircle className="text-green-400 h-6 w-6 flex-shrink-0 mt-1" />
                  <div>
                    <h3 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-emerald-500 mb-4">
                      After Using Profit Hunter
                    </h3>
                    <ul className="space-y-4">
                      <li className="flex items-start gap-2">
                        <span className="text-green-400">-</span>
                        <p className="text-green-200">
                          I've never bought so many items. My profit this month is through the roof.
                        </p>
                      </li>
                      <li className="flex items-start gap-2">
                        <span className="text-green-400">-</span>
                        <p className="text-green-200">
                          I am spending more than ever before whilst spending less time on my business.
                        </p>
                      </li>
                      <li className="flex items-start gap-2">
                        <span className="text-green-400">-</span>
                        <p className="text-green-200">
                          Now I manage the notifications with ease.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </section>

        <section className="py-24 bg-gradient-to-b from-purple-900/50 to-purple-950/50">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
              {stats.map((stat, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="text-center"
                >
                  <div className="text-4xl font-bold mb-2 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-500">
                    {stat.value}
                  </div>
                  <div className="text-xl text-gray-300">{stat.label}</div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        <section id="features" className="py-24 bg-black/50">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold text-center mb-16">
              One place to find profitable items{' '}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-500">
                effortlessly
              </span>
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-gradient-to-br from-purple-900/30 to-pink-900/30 backdrop-blur-xl border border-purple-500/20 p-8 rounded-xl"
                >
                  <feature.icon className="h-12 w-12 text-purple-400 mb-6" />
                  <h3 className="text-xl font-semibold mb-4">{feature.title}</h3>
                  <p className="text-gray-300">{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        <section className="py-24 bg-gradient-to-b from-gray-900/50 to-purple-950/50">
          <div className="container mx-auto px-4">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              className="text-center mb-16"
            >
              <h2 className="text-5xl md:text-6xl font-bold mb-8">
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-500">
                  Stop
                </span>{' '}
                spending hours hunting for profits.
              </h2>
              <h2 className="text-5xl md:text-6xl font-bold">
                Let{' '}
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-500">
                  Profit Hunter
                </span>{' '}
                find them for you.
              </h2>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              whileInView={{ opacity: 1, scale: 1 }}
              className="flex justify-center items-center"
            >
              <img
                src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Untitled%20Design%202310x1331%20(5)-wTFyVS5YwRMhsgSUxvQUlHHueG1MdL.png"
                alt="Profit Hunter Dashboard"
                className="w-full max-w-5xl rounded-2xl shadow-2xl border border-purple-500/20"
              />
            </motion.div>
          </div>
        </section>

        <section id="pricing" className="py-24 bg-black/50">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold text-center mb-16">
              Turn Minutes Into Money with{' '}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-500">
                Profit Hunter
              </span>
            </h2>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              className="max-w-md mx-auto"
            >
              <div className="bg-gradient-to-br from-purple-900/30 to-pink-900/30 backdrop-blur-xl border border-purple-500/20 p-8 rounded-xl relative">
                <div className="absolute -top-3 right-8 bg-gradient-to-r from-purple-600 to-pink-600 px-4 py-1 rounded-full">
                  <span className="text-sm font-medium">Launch Price</span>
                </div>
                <div className="flex items-center gap-2 mb-4">
                  <span className="text-xl font-semibold">UK</span>
                  <span className="text-xl">🇬🇧</span>
                </div>
                <p className="text-gray-300 mb-6">Earn a full time income with part time hours</p>
                <div className="flex items-baseline mb-8">
                  <span className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-500">
                    £49.99
                  </span>
                  <span className="text-gray-400 ml-2">/mo</span>
                  <span className="text-gray-500 ml-2 line-through">£59.99</span>
                </div>
                <ul className="mb-8 space-y-4 text-gray-300">
                  {features.map((feature, index) => (
                    <li key={index} className="flex items-center">
                      <Check className="h-5 w-5 text-purple-400 mr-3 flex-shrink-0" />
                      {feature.title}
                    </li>
                  ))}
                </ul>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={handleCheckout}
                  className="w-full py-4 px-6 bg-gradient-to-r from-purple-600 to-pink-600 rounded-full font-medium hover:opacity-90 transition-opacity"
                >
                  Boost Your Income Now
                </motion.button>
              </div>
            </motion.div>
          </div>
        </section>

        <section id="faq" className="py-24 bg-black/50">
          <div className="container mx-auto px-4">
            <h2 className="text-2xl font-bold text-gray-200 mb-2">FAQ</h2>
            <h3 className="text-4xl font-bold mb-12">Frequently Asked Questions</h3>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              className="max-w-3xl mx-auto space-y-4"
            >
              {faqs.map((faq, index) => (
                <div 
                  key={index} 
                  className="border-b border-purple-500/20"
                >
                  <button
                    className="flex justify-between items-center w-full py-4 text-left text-gray-200 hover:text-purple-400 transition-colors focus:outline-none"
                    onClick={() => toggleQuestion(index)}
                  >
                    <span className="text-lg font-medium">{faq.question}</span>
                    {expandedIndex === index ? (
                      <Minus className="h-5 w-5" />
                    ) : (
                      <Plus className="h-5 w-5" />
                    )}
                  </button>
                  {expandedIndex === index && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                      className="pb-4"
                    >
                      <p className="text-gray-300">{faq.answer}</p>
                    </motion.div>
                  )}
                </div>
              ))}
            </motion.div>
          </div>
        </section>
      </main>
    </div>
  )
}
